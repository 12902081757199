import React from "react"
import Layout from "../components/layout/Layout/Layout"
import { graphql } from "gatsby"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import GridContainer from "../components/layout/GridContainer/GridContainer"
import HTMLWrapper from "../components/HTMLWrapper/HTMLWrapper.js"
import UserContent from "../components/content-modules/UserContent"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      ...Article
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
    }
  }
`

const Article = props => {
  const buildHeaderImages = (baseImages) => {
    const processedHeaderImages = []
    for (let i = 0; i < baseImages.length; i++) {
      processedHeaderImages.push({
        sources: [
          baseImages[i].fluid,
          {
            ...props.data.datoCmsPage.headerImagesTablet[i].fluid,
            media: `(min-width: 769px)`,
          },
          {
            ...props.data.datoCmsPage.headerImagesDesktop[i].fluid,
            media: `(min-width: 1024px)`,
          },
          {
            ...props.data.datoCmsPage.headerImagesWidescreen[i].fluid,
            media: `(min-width: 1216px)`,
          },
        ],
        title: baseImages[i].title,
        alt: baseImages[i].alt
      })
    }
    return processedHeaderImages;
  }

  return (
    <Layout
      metaTitle={props.data.datoCmsPage.seo ? props.data.datoCmsPage.seo.title : props.data.datoCmsPage.title}
      metaDescription={props.data.datoCmsPage.seo ? props.data.datoCmsPage.seo.description : null}
      metaImage={
        props.data.datoCmsPage.seo && props.data.datoCmsPage.seo.image ? props.data.datoCmsPage.seo.image.url : null
      }
      twitterCard={
        props.data.datoCmsPage.seo && props.data.datoCmsPage.seo.twitterCard
          ? props.data.datoCmsPage.seo.twitterCard
          : null
      }
      pathname={props.uri}
    >
      <HeroBanner
        title={props.data.datoCmsPage.title}
        images={buildHeaderImages(props.data.datoCmsPage.headerImages)}
      />
      <GridContainer>
        <Breadcrumb url={props.uri} />
        <HTMLWrapper text={props.data.datoCmsPage.introductoryText} />
        {props.data.datoCmsPage.content.map(contentModule => {
          return (
            <UserContent
              content={contentModule}
              pageSlug={props.pageContext.slug}
              key={contentModule.id}
            />
          )
        })}
      </GridContainer>
    </Layout>
  )
}

export default Article
